import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import LazyWrapper from '../LazyWrapper';
// Lazy components
const IconFeather = lazy(() => import('../../iconsPictos/IconFeather'));

import './styles.scss';

const Button = props => {
  const { icon, cssId, style, topClass, design, text, ...buttonProps } = props;
  // use the proper css class according to the 'design' prop
  const designClass = design ? `button__style-${design}` : 'button__style-secondary';
  const iconElement =
    typeof icon === 'string' ? (
      <LazyWrapper>
        <IconFeather icon={icon} />
      </LazyWrapper>
    ) : (
      icon
    );
  return (
    <button
      className={`${designClass} ${topClass} button__style-basic`}
      id={cssId}
      style={style}
      data-testid={cssId}
      {...buttonProps}
    >
      {icon && <span className="button__icon">{iconElement}</span>}
      {text && <span>{text}</span>}
    </button>
  );
};

Button.defaultProps = {
  // CSS
  cssId: null,
  design: 'secondary',
  topClass: '',
  // UI
  icon: null,
  type: 'button',
  disabled: false,
  // user interaction
  onClick: () => {},
  onMouseEnter: () => {},
};

Button.propTypes = {
  text: PropTypes.string.isRequired, // text to display on the button
  // optional
  cssId: PropTypes.string, // cssId to identify the button
  design: PropTypes.string, // the style to use for this button
  disabled: PropTypes.bool, // true to disable the button
  form: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func, // function to call on click
  onMouseEnter: PropTypes.func, // function to call when the mouse enters the button
  style: PropTypes.object,
  topClass: PropTypes.string, // css classes to apply on that button
  type: PropTypes.string, // Use to set type submit
};

export default Button;
