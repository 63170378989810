import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

/**
 * Alert component to display messages with different severity levels.
 *
 * @param {object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed inside the alert.
 * @param {'success' | 'info' | 'warning' | 'error'} props.severity - The severity level of the alert.
 * @param {React.ReactNode} [props.icon] - An optional icon to display in the alert.
 * @param {React.ReactNode} [props.action] - An optional action element to display in the alert.
 * @param {boolean} [props.center] - Whether to center the content inside the alert.
 *
 * @returns {JSX.Element} The rendered Alert component.
 */
const Alert = props => {
  const { children, severity, icon, action, center } = props;
  return (
    <div className={`catalizr-alert catalizr-alert__${severity}`}>
      {icon}
      <div className={`catalizr-alert__content ${center ? 'center' : ''}`}>{children}</div>
      {action}
    </div>
  );
};

Alert.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
  action: PropTypes.node,
  center: PropTypes.bool,
};

export default Alert;
