import React from 'react';
import { useIntl } from 'react-intl';

import './styles.scss';

const CatalizrLoader = () => {
  const intl = useIntl();
  return (
    <div className="loader-content">
      <div className="vertical-align-center">
        <div className="text-align-center">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <br />
          {intl.formatMessage({ id: 'components.loading' })}
        </div>
      </div>
    </div>
  );
};

export default CatalizrLoader;
