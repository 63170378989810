import React, { Fragment, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Components
import NavbarAccount from '../NavbarAccount';
import IconCatalizrLine from '../../iconsPictos/iconCatalizrLine';

// Lazy Loading
const IconBank = lazy(() => import('../../iconsPictos/iconBank'));
const IconAmpersand = lazy(() => import('../../iconsPictos/iconAmpersand'));
import LazyWrapper from '../LazyWrapper';

// Utils
import { getEntityPathFromUserType } from '../../shared/utils/user';
import useReactHookFormDataStorage from '../../shared/hooks/react-hook-form-data-storage';
import { getSubdomain } from '../../shared/utils/helper';

// Actions
import { setJourney } from '../../redux/actions';
import { resetCurrentCommentAfterSubmit } from '../../pages/dashboard/common/actions/dashboard.actions';
import { setSimulateFundAppeal } from '../../pages/funds/fund-appeal/actions/fund-appeal.actions';

import './styles.scss';
import { setLocale } from '../../shared/actions/actions/user.actions';
import DropdownSelect from '../DropdownSelect';
import { isFeatureEnabled } from '../../shared/utils/CatalizrUtils';
import { FEATURE_FLAG } from '../../shared/utils/featureFlag';

const Topbar = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userTheme = useSelector(state => state.user.theme);
  const userType = useSelector(state => state.user.type);
  const userLocale = useSelector(state => state.user.locale);
  const { hideAccountInfos, resetDashboardFilters } = props;
  // custom hook
  const { clearFormDataStorage } = useReactHookFormDataStorage();

  const subdomain = getSubdomain();
  return (
    <nav className="navbar" data-testid="topbar">
      <div
        className="navbar__brand"
        data-testid="topbar_catalizr"
        onClick={() => {
          // clear local storage
          clearFormDataStorage('customFormValues'); // template model custom data
          clearFormDataStorage('fundAppealFormValue'); // fund-appeal data
          // clean store
          dispatch(setJourney('')); // journey to not keep isFeatureEnable in simulate mode
          dispatch(resetCurrentCommentAfterSubmit()); // to not commit comment when form are not submitted
          dispatch(setSimulateFundAppeal([])); // clean fund appeal creation data
          // Provided by SharesValuationHeader to reset pagination, search and sort from positions page
          resetDashboardFilters();
          history.push(`/${getEntityPathFromUserType(userType)}/dashboard/refresh`);
        }}
      >
        {userTheme !== 'default' && (
          <Fragment>
            <LazyWrapper>
              <IconBank bankDomain={subdomain} />
              <IconAmpersand width="20" height="50" alt="ampersand logo" title="ampersand logo" />
            </LazyWrapper>
          </Fragment>
        )}
        <IconCatalizrLine width="260px" height="50px" alt="Catalizr logo" title="Catalizr logo" />
      </div>
      {isFeatureEnabled(FEATURE_FLAG.display_experimental) && (
        <div>
          <DropdownSelect
            clearable={false}
            options={[
              {
                label: 'fr',
                id: 'fr',
              },
              {
                label: 'en',
                id: 'en',
              },
              {
                label: 'es',
                id: 'es',
              },
            ]}
            selectedValue={userLocale}
            onChange={event => {
              dispatch(setLocale(event));
            }}
          />
        </div>
      )}
      {hideAccountInfos !== true && <NavbarAccount />}
    </nav>
  );
};

Topbar.defaultProps = {
  resetDashboardFilters: () => {},
  hideAccountInfos: false,
};

Topbar.propTypes = {
  hideAccountInfos: PropTypes.bool,
  // FIXME rename to cleanBeforeRedirection
  // no renaming because many dispatch props in *.container.js have this name props
  resetDashboardFilters: PropTypes.func,
};

export default Topbar;
